import {Subject} from "rxjs";
import {Injectable} from "@angular/core";

export class BaseService {
  constructor() {
    // this.unsub = serviceConfig.unsub
  }

}

