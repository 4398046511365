import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItems } from './api/menuitems';
import { AuthService } from '../site/service/auth.service';
import { WaitlistService } from '../site/service/waitlist.service';
import { History } from '../site/api/history';
import {Subject} from "rxjs";

@Component({
	selector: 'app-menu',
	templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

	constructor(private authService: AuthService, private waitlistService: WaitlistService) { }

	model: any[] = [];

	ngOnInit() {
    this.authService.requiresReassert(new Subject<void>()).subscribe((reassert) => {
      if (!reassert) {
        this.authService.getClaims().subscribe((claims) => {
          this.model = []
          let role = claims.role;
          for (let m of MenuItems) {
            let mi: any = {
              label: m.label,
              icon: m.icon,
              items: [],
              canActivate: []
            };
            for (let item of m.items) {
              if (item.canActivate.includes(role)) {
                mi.items.push(item);
              }
            }
            if (m.canActivate.includes(role)) {
              this.model.push(mi);
            }
          }
        })

      }
      else {
        this.model = [];
      }
    })

	}
}
